<template>
  <div class="create-news">
    <NewsAppbar />
    <div class="ma-4">
      <NewsForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create News
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import NewsAppbar from '@/views/Home/News/components/NewsAppbar'
import NewsForm from '@/views/Home/News/components/NewsForm'

export default {
  components: {
    NewsAppbar,
    NewsForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'news/clearNewsDetails',
    }),
  },
}
</script>
<style lang="scss">
.create-news {
  //
}
</style>
